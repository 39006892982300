import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useNavigate }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as PricesService } from 'services/Prices';

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;

	h2 {
		display: block;
		text-align: center;
		font-size: 20px;
		letter-spacing: -1px;
		margin: 0;
	}

	hr {
		margin: 0;
		padding: 0;
		border-color: var(--bs-body-color);
		opacity: 1;
	}

	.title {
		margin-bottom: 40px;
	}
	
	.date {
		margin-bottom: 5px;
	}

	.intervals {
		text-align: center;
		padding: 0 10px;
	}

	.stations {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-top: 40px;
		overflow: auto;
	}
`;

const BackButton = styled.button`
	width: 30px;
	height: 30px;
	position: absolute;
	top: -40px;
	left: 30px;
	margin-left: -15px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-black);
	border-radius: 50%;
	font-size: 20px;
`;

const Interval = styled.div`
	display: inline-flex;
	align-items: center;
	background: ${props => props.transparent ? 'transparent' : 'var(--plenoil-blue)'};
	background-color: ${props => props.current ? 'var(--plenoil-orange)' : ''};
	color: ${props => props.transparent ? 'var(--plenoil-blue)' : 'white'};
	border: 1px solid ${props => props.current ? 'var(--plenoil-orange)' : 'var(--plenoil-blue)'};
	border-radius: 30px;
	padding: 2px 10px;
	margin: 5px;
	white-space: nowrap;

	i {
		margin-left: 5px;
		font-size: 10px;
	}
`;

const Station = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	width: 250px;
	line-height: 100%;
	text-align: left;
	font-size: 18px;

	border: 1px solid ${props => props.current ? 'var(--plenoil-orange)' : 'var(--plenoil-blue)'};
	border-radius: 30px;
	padding: 2px 10px;

	&:hover,
	&:active {
		background: var(--plenoil-blue-light);
		cursor: pointer;
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		border-radius: 50%;
		width: 25px;
		height: 25px;
		min-width: 25px;
		margin-right: 10px;
		background: var(--plenoil-blue);
		color: white;

		&.check {
			opacity: 0;

			&.visible {
				opacity: 1;
				color: var(--plenoil-yellow);
				font-size: 18px;
			}
		}
	}
`;

export default function PricesControl() {
	let navigate = useNavigate();

    let [intervals, setIntervals] = useState(null);
    let [stations, setStations] = useState(null);

	useEffect(() => {		
		const getData = async () => {
			let intervals = await PricesService.getCompetitionIntervalList();
			setIntervals(intervals);

			let stations = await PricesService.getCompetitionStationsList(intervals.filter(el => el.current === true)[0]?.id ?? null);
			setStations(stations ?? []);
		};

		let getDataInterval = setInterval(() => {
			getData();
		}, 5000);
		getData();

		return function cleanup() {
			clearInterval(getDataInterval);
			PricesService.cancel();
		}
	}, [navigate]);


	return (
		<LoggedLayout hideFooter={true} hideHeader={false}>
			<BackButton onClick={() => navigate('/')}>
				<i className="bi bi-chevron-left"></i>
			</BackButton>

			<Wrapper>
				<div className="title">
					<h2>CONTROL DE PRECIOS</h2>
					<hr />
					<h2>TOMA DE DATOS</h2>
				</div>
                
				<div className="date">
					<h2>{moment().format('DD/MM/YYYY')}</h2>
				</div>

				<div className="intervals">
					{intervals?.map((el, idx) => {
						return (
							<Interval key={idx} transparent={el.status === 0} current={el.current}>
								{el.from} - {el.to}
								{el.status === 0 && <i className="bi bi-clock text-secondary"></i>}
								{(el.status === -1 && !el.current) && <i className="bi bi-x-circle-fill text-plenoil-red"></i>}
								{el.status === 1 && <i className="bi bi-check text-plenoil-yellow"></i>}
							</Interval>
						);
					})}
				</div>

				<div className="stations">
					{stations?.map((el, idx) => {
						return (
							// <Station key={idx} onClick={() => !el.visited ? navigate('/pricescontrol/' + el.id) : null}>
							<Station key={idx} onClick={() => navigate('/pricescontrol/' + el.id)}>
								<span className={'icon check ' + (el.visited === 1 ? 'visible' : '')}><i className="bi bi-check"></i></span>
								<span className="icon"><i className="bi bi-fuel-pump-fill"></i></span>
								{el.name}
							</Station>
						);
					})}

					{stations?.length === 0 &&
						<Station className="empty">
							<div className="text-center">
								Espere hasta la próxima visita
							</div>
						</Station>
					}
				</div>
			</Wrapper>
		</LoggedLayout>
	);
}


