import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useNavigate }  from 'react-router-dom';
import { assets } from 'helpers/generic';
import { hasCapability } from 'helpers/user';
import TankerTrucks from 'services/TankerTrucks';

const PendingTankerTruckFooter = styled.div` 
	background: var(--plenoil-red);
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	color: white;
	cursor: pointer;
	padding: 5px 10px;
	padding-top: 10px;

	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	.openButton {
		width: 30px;
		height: 30px;
		position: absolute;
		top: -15px;
		left: 50%;
		margin-left: -15px;
		display: flex;
		justify-content: center;
		align-items: start;
		border: 1px solid white;
		color: white;
		background: var(--plenoil-orange);
		border-radius: 50%;
		font-size: 20px;
	}

	.left {
		height: 40px;
		margin-right: 10px;

		img {
			height: 80%;
		}
	}

	.right {
		div {
			font-size: 16px;
			line-height: 14px;
		}

		span {
			font-size: 12px;
		}
	}
`;


export default function PendingTankerTrucks() {
	let navigate = useNavigate();

	let [tankerTruck, setTankerTruck] = useState(null);
	let [tankerTruckNext, setTankerTruckNext] = useState(null);

	// Check if pending tankertruck
	useEffect(() => {
        if ( !hasCapability('app_tankertrucks') ) return;

		const getData = async () => {
			let currents = await TankerTrucks.getCurrentsList();
			if ( currents ) currents = currents.filter((tankertruck) => tankertruck.status !== 5); // Fix to prevent show pending tanker trucks with status 5 that are have a OCR process working on images

			let nexts = await TankerTrucks.getNextsList();

			setTankerTruck(currents[0] ?? null);
			setTankerTruckNext(nexts[0] ?? null);
		};
		getData();

		let pendingTankertruckInterval = setInterval(() => {
			getData();
		}, 10000);		

		return function cleanup() {
			clearInterval(pendingTankertruckInterval);
			TankerTrucks.cancel();
		}
	}, []);

	return (
        <React.Fragment>
			{(tankerTruck || tankerTruckNext) &&
                <PendingTankerTruckFooter onClick={() => navigate('/tankertrucks')}>
                    <button className="openButton">
                        <i className="bi bi-chevron-up"></i>
                    </button>
                    <div className="left">
                        <img src={assets('/img/cisternas-icono.svg')} alt="camion" />
                    </div>
                    <div className="right">
                        { tankerTruck &&
                            <div className="mb-2">
                                <div>PRÓXIMA { moment(tankerTruck.date).format('DD-MM-YYYY') }</div>
                                <span>{ moment('2022-09-16 ' + tankerTruck.hour_expected_init).format('HH:mm')} - {moment('2022-09-16 ' + tankerTruck.hour_expected_end).format('HH:mm') }</span>
                                { tankerTruck.parent &&
                                    <div><span>Desvío desde <u>{tankerTruck.parent?.station?.name ?? '-'}</u></span></div>
                                }
                            </div>
                        }
                        { tankerTruckNext &&
                            <div> 
                                SIGUIENTE { moment(tankerTruckNext.date).format('DD-MM-YYYY') } <br />
                                <span>{ moment('2022-09-16 ' + tankerTruckNext.hour_expected_init).format('HH:mm')} - {moment('2022-09-16 ' + tankerTruckNext.hour_expected_end).format('HH:mm') }</span>
                            </div>
                        }
                    </div>
                </PendingTankerTruckFooter>
            }
        </React.Fragment>
	);
}


