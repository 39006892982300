import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams }  from 'react-router-dom';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import LoggedLayout from '../../layouts/LoggedLayout';
import { assets, jsonToArray } from 'helpers/generic';
import resizeImage from 'resize-image';
import { getUser } from 'helpers/user';
import { default as ChatService } from 'services/Chat';
import NotificationLightAudio from './notification_light.mp3';
import Message from './Message';

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
`;

const Header = styled.div` 
	display: flex;
	align-items: center;
	width: 100%;
	height: 60px;
	padding: 0px 15px;

	img {
		width: 50px;
		height: 50px;
		margin-right: 10px;
	}

	div.name {
		line-height: 15px;

		span {
			color: var(--bs-body-color);
		}

		i {
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			font-style: normal;
		}
	}
	

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--plenoil-blue-light);
		color: white;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		margin-left: auto;
	}
`;

const MessagesSection = styled.div`
    display: flex;
    flex-direction: column;
	height: calc(100% - 60px);
	width: 100%;
	padding-top: 5px;

    .messages-container {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 5px;
        padding: 5px;
    }
    
    .textbox {
		display: flex;
        height: 100px;
		padding: 5px 0px;

		.upload-image-wrapper {
			display: flex;
			align-items: center;

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--plenoil-yellow);
				background: var(--plenoil-blue);
				font-size: 15px;
				border-radius: 50%;
				width: 20px;
				height: 25px;
				margin: 10px;
			}

			input[type=file] {
				height: 0;
				width: 0;
				opacity: 0;
			}
		}

		textarea {
            resize: none;
            font-size: 11px;
            line-height: 12px;
        }

		& > button {
			color: var(--plenoil-orange);
			font-size: 30px;
			border: 0;

			&:hover {
				color: var(--plenoil-orange);
			}
		}
    }
`;

let last_seen_message_id = null;
let reading = false;
let notificationLightAudio = new Audio(NotificationLightAudio);
notificationLightAudio.volume = 0.3;

export default function Chat() {
	let navigate = useNavigate();
	let params = useParams();

	let channel = params.channel;

	const fileRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const lastMessageRef = useRef(null);

	let [station, setStation] = useState(null);
	let [errors, setErrors] = useState([]);
    let [messages, setMessages] = useState([]);
    let [sending, setSending] = useState(false);
    let [message, setMessage] = useState('');

	useEffect(() => {
		const getData = async () => {
			let data = await ChatService.getStations();
			setStation(data.filter(el => el.channel.id === channel)[0] ?? null);
		};
		getData();

		return function cleanup() {
			ChatService.cancel();
		}
	}, [channel]);

	const getMessages = useCallback(async () => {
        // Prevent reading messages if currently reading
        if ( reading ) {
            // Try again after some milliseconds
            setTimeout(() => {
                getMessages();
            }, 500);

            return;
        };

        reading = true;

        let newMessages = await ChatService.getMessages(station.id, channel, last_seen_message_id);
        if ( newMessages && newMessages.length > 0 ) {
            // Play sound
            let newMessagesDifferentFromMe = newMessages.filter(el => el.seen_at === null && el.user_id !== getUser()?.id).length > 0;
            if ( last_seen_message_id && newMessagesDifferentFromMe ) {
                notificationLightAudio.play();
            }

            // Set messages
            setMessages(prev => [...prev, ...newMessages]);
            last_seen_message_id = [...newMessages].pop()?.id;
        }

        reading = false;

		let appActive = (await App.getState()).isActive;
		if ( appActive ) PushNotifications.removeAllDeliveredNotifications(); // Remove push notifications from bar
    }, [station?.id, channel]);

    const sendMessage = async () => {
        if ( sending ) return; // Prevent send if currently sending

        setErrors([]);
        setSending(true);
        
        let result = await ChatService.sendMessage(station.id, channel, message, true);
        if ( result && result.status ) {
            setMessage('');
            getMessages();
        } else {
            let errorsFormatted = jsonToArray(result).map(el => el.value[0] ?? null).filter(el => el !== null);
            setErrors(errorsFormatted);
        }

        setSending(false);
    }

    const uploadImage = async (event) => {
        let file = event.target.files[0];

        // Check extension
        let nameSplit = file.name.split('.');
        let extension = nameSplit.pop();
        if ( ['jpg', 'jpeg', 'png', 'bmp', 'gif'].indexOf(extension) === -1 ) {
            setErrors(['El fichero de imágen tiene un formato inválido']);
            return;
        }

        // Read file
        var reader = new FileReader();
        reader.onload = () => {            
            var dataUrl = reader.result;
            
            // Change size to image
            let img = new Image();
            img.onload = async () => {
                let data = resizeImage.resize(img, 512, null, resizeImage.JPEG);

                // Convert to blob
                let blob = await fetch(data).then(res => res.blob());
                
                // Upload
                setErrors([]);
                setSending(true);
                let result = await ChatService.uploadImage(station.id, channel, blob);
                if ( result.status === 1 ) {
                    getMessages();
                } else {
                    let errorsFormatted = jsonToArray(result).map(el => el.value[0] ?? null).filter(el => el !== null);
                    setErrors(errorsFormatted);
                }
                setSending(false);
            }
            img.src = dataUrl;
        };
        reader.readAsDataURL(file);
    }

	useEffect(() => {
		if ( !station?.id ) return;

		const getMessagesInterval = setInterval(() => {
            getMessages();
        }, 2000);

		setMessages([]); // First load set empty messages (needed when developing for hot reloading)
        getMessages();
       
        return function cleanup() {
            clearInterval(getMessagesInterval);
            last_seen_message_id = null;
            reading = false;
        }
	}, [station?.id, getMessages]);


    useEffect(() => {
        let lastUnseenMessage = messages.filter(el => el.seen_at === null && el.user_id !== getUser()?.id).pop();
        if ( lastUnseenMessage ) {
            ChatService.readMessage(lastUnseenMessage.id);
        }
    }, [messages]);

	return (
		<LoggedLayout hideFooter={true}>
			<Wrapper>
				<Header>
					<img src={assets('/img/chat.svg')} alt="Chat" />
					<div className="name">
						<span>{station?.name}</span>
						<i style={{color: station?.channel?.color}}>{station?.channel?.name}</i>
					</div>
					<button className="btn btn-sm" onClick={() => navigate('/chatselector')}><i className="bi bi-chevron-left"></i></button>
				</Header>
				<MessagesSection>
					<div className="messages-container" ref={messagesContainerRef}>
						{messages.map((el, idx) => {
							return (
								<Message 
									key={idx} 
									ref={idx === messages.length-1 ? lastMessageRef : null} 
									{...el}
									parentBoundingClientRect={messagesContainerRef.current.getBoundingClientRect()}
								/>
							);
						})}
						{errors?.map((el, idx) => {
							return (
								<div key={'e'+idx} className="text-danger">{el}</div>
							)
						})}
					</div>
					<div className="textbox">
						<div className="upload-image-wrapper">
							<button 
								className="btn" 
								onClick={() => {
									fileRef.current.value = null;
									fileRef.current.click();
								}}
							>
								<i className="bi bi-camera-fill"></i>
							</button>
							<input type="file" ref={fileRef} onChange={uploadImage}  />
						</div>
						<textarea 
							type="text" 
							className="form-control" 
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							onKeyDown={(e) => {
								if ( e.key === 'Enter' ) {
									sendMessage();
									e.preventDefault();
								} 
							}} 
							rows="2"
						/>
						<button className="btn" type="button" disabled={sending || message.trim().length === 0} onClick={sendMessage}><i className="bi bi-telegram"></i></button>
					</div>
				</MessagesSection>
			</Wrapper>
		</LoggedLayout>
	);
}


