import React, { useEffect, useState } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { useNavigate, useLocation } from "react-router-dom";
import moment from 'moment';
import styled from 'styled-components';
import Users from 'services/Users';
import TankerTrucks from 'services/TankerTrucks';
import { assets } from 'helpers/generic';

const ConfirmModalStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 30px;
    background: var(--plenoil-red);
    color: white;
    z-index: 9999;
`;

const TruckIcon = styled.div` 
	display: inline-block;
    margin-bottom: 20px;
	
	div {
		background: var(--plenoil-blue);
		border: 4px solid white;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		padding: 15px;
	}
`;

let location = null;

export default function PushHandler() {
    let navigate = useNavigate();
    location = useLocation();

    let [showConfirmTankerTruckModal, setShowConfirmTankerTruckModal] = useState(false);

    const confirmTankerTruckSeen = async () => {
        await TankerTrucks.updateField(showConfirmTankerTruckModal, 'seen_at', moment().format('YYYY-MM-DD HH:MM'));
        setShowConfirmTankerTruckModal(false);
    }

    useEffect(() => {

        let init = async () => {
            // Check platform
            let device = await Device.getInfo();
            if ( device.platform === 'web' ) return;

            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            PushNotifications.requestPermissions().then(result => {
                if (result.receive === 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
                } else {
                    // Show some error
                }
            });
        
            PushNotifications.addListener('registration', (token) => {
                const updatePushToken = async () => {
                    const info = await Device.getInfo();
                    Users.updatePushToken(token.value, info.platform);
                };
                updatePushToken()
            });
        
            PushNotifications.addListener('registrationError', (error: any) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            });
        
            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                // console.log('Push received: ', notification);
                if ( notification.data.action && notification.data.action === 'tanker-truck-status-1' ) {
                    setShowConfirmTankerTruckModal(notification.data.tankertruckId);
                }  

                if ( notification.data.action && notification.data.action === 'open-chat' ) {
                    setShowConfirmTankerTruckModal(notification.data.tankertruckId);
                    
                    if ( location.pathname.indexOf('/chat/') !== -1 ) {
                        // TODO TO FUTURE: Show local notifications
                    }
                }  
            });
        
            PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
                // console.log('Push action performed: ', notification);
                let notif = notification.notification;
                if ( notif.data.action && notif.data.action === 'tanker-truck-status-1' ) {
                    setShowConfirmTankerTruckModal(notif.data.tankertruckId);
                }  

                if ( notif.data.action && notif.data.action === 'open-chat' ) {
                    navigate('/chat/' + notif.data.channel);
                }  
            });
        }
        init();
        
    }, [navigate]);

	return (
		<React.Fragment>
            { showConfirmTankerTruckModal &&
                <ConfirmModalStyled>
                    <TruckIcon>
                        <div><img src={assets('/img/cisternas-icono.svg')} alt="Comprobación" /></div>
                    </TruckIcon>
                    Cisterna en camino
                    <button className="btn btn-plenoil-orange mt-4" onClick={confirmTankerTruckSeen}>Aceptar</button>
                </ConfirmModalStyled>
            }
		</React.Fragment>
	)
}