import React, { useEffect, useState } from 'react';
import LoggedLayout from 'layouts/LoggedLayout';
import styled from 'styled-components';
import { useNavigate }  from 'react-router-dom';
import { hasCapability, getUser } from 'helpers/user';
import { assets } from 'helpers/generic';
import PricesCheck from './PricesCheck';
import TankerTrucksWithOcrErrors from './TankerTrucksWithOcrErrors';
import PendingTankerTrucks from './PendingTankerTrucks';
// import StationName from './StationName';
import PricesControlCurrentIntervalChecker from './PricesControlCurrentIntervalChecker';
import ChatPendingMessagesIntervalChecker from './ChatPendingMessagesIntervalChecker';
import ConteosPendingIntervalChecker from './ConteosPendingIntervalChecker';
import BCOrdersToCheckPendingIntervalChecker from './BCOrdersToCheckPendingIntervalChecker';
import Clock from './Clock';
import SuppliesPendingCheck from './SuppliesPendingCheck';

const DashboardContainer = styled.div` 
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const ButtonsContainer = styled.div`
	width: 100%;
	/* min-height: 440px; */
	/* max-height: 440px; */
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	margin-top: auto;
	padding-bottom: 20px;
`;

const Button = styled.button`
	position: relative;
	background: transparent;
	border: 0;
	display: flex;
	align-items: center;
	height: 45px;
	width: 60%;
	min-width: 220px;
	background: white;
	margin-bottom: 8px;
	border-radius: 40px;
	padding: 0;
	box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);

	&[disabled] {
		opacity: 0.3;
	}

	&:nth-child(1) {
		/* border: 1px solid #333333; */
		/* border-left: 0; */
	}

	&:nth-child(2) {
		/* border-top: 1px solid #333333; */
		/* border-bottom: 1px solid #333333; */
	}

	&:nth-child(3) {
		/* border-right: 1px solid #333333; */
	}
`;	

const Circle = styled.img`
	height: 100%;
`;

const Title = styled.div` 
	width: 100%;
	text-align: left;
	text-transform: uppercase;
	font-size: 15px;
	letter-spacing: -0.5px;
	font-weight: 600;
	color: black;
	padding-left: 10px;
`;

export default function Dashboard() {
	let navigate = useNavigate();

	return (
		<LoggedLayout 
			// headerChild={<StationName/>}
			footerChild={<PendingTankerTrucks/>}
		>
			<DashboardContainer id="dashboard-container">
				<PricesCheck />
				<TankerTrucksWithOcrErrors />
				<SuppliesPendingCheck />
				<Clock />
				<ButtonsContainer id="buttons-container">
					{ hasCapability('app_tankertrucks') &&
						<Button onClick={() => navigate('/tankertrucks')}>
							<Circle src={assets('/img/cisternas.svg')} alt="Cisternas" />
							<Title>Cisterna</Title>
						</Button>
					}	
					{ hasCapability('app_prices') &&
						<Button onClick={() => navigate('/pricescontrol')}>
							<PricesControlCurrentIntervalChecker />
							<Circle src={assets('/img/controlprecios.svg')} alt="Control de precios" />
							<Title>Control de precios</Title>
						</Button>
					}
					{ hasCapability('app_supplies') &&
						<Button onClick={() => navigate('/supplies')} disabled={!getUser()?.stations[0] || getUser()?.stations[0] === 'all'}>
							<BCOrdersToCheckPendingIntervalChecker />
							<Circle src={assets('/img/suministros.svg')} alt="Inventario" />
							<Title>Inventario</Title>
						</Button>
					}
					{ hasCapability('app_conteos') &&
						<Button onClick={() => navigate('/conteos/list')}>
							<ConteosPendingIntervalChecker />
							<Circle src={assets('/img/conteo-icono.svg')} alt="Conteos" style={{padding: '2px'}} />
							<Title>Conteos</Title>
						</Button>
					}

					<Button onClick={() => navigate('/chatselector')}>
						<ChatPendingMessagesIntervalChecker />
						<Circle src={assets('/img/chat.svg')} alt="Chat" />
						<Title>Chat</Title>
					</Button>
				</ButtonsContainer>
			</DashboardContainer>
		</LoggedLayout>
	);
}


