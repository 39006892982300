import { assets } from "helpers/generic";
import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import StationName from "components/StationName";

const ScreenWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

`;

const Container = styled.div`
	background-color: #fef8f7;
	background: url(${assets('/img/fondo-nuevo.jpg')});
	background-size: cover;
	max-width: 400px;
	height: 100%;
	overflow: auto;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	height: 50px;
	min-height: 50px;
	width: 100%;
	position: relative;

	#header-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		img {
			width: 100px;
			margin-left: 10px;
			filter: brightness(0) invert(1);
		}

		span {
			display: flex;
			justify-content: flex-end;
			text-align: right;
			color: var(--plenoil-blue);
			margin-left: auto;
			margin-right: 10px;
			font-size: 13px;
			line-height: 13px;
		}

		a {
			display: inline-flex;
			width: 20px;
			height: 20px;
			justify-content: center;
			align-items: center;
			background: var(--plenoil-black);
			color: white;
			border-radius: 50%;
			font-size: 15px;
			margin-right: 10px;
		}
	}

	#header-bottom {

	}

	
`;

const Content = styled.div`
	position: relative;
	flex-grow: 1;
`;

const Footer = styled.div`
	position: relative;
	min-height: 100px;
	height: 100px;
	width: 100%;
	background: var(--plenoil-blue);
`;

export default function AppLayout(props) {
	
	return (
		<Container id="container">
			{ !props.hideHeader &&
				<Header id="header">
					<div id="header-top">
						<img src={assets('/img/logo-plenoil.svg')} alt="logo" />
					
						<NavLink to="/logout"><i className="bi bi-power"></i></NavLink>
						<div className="header-child"><StationName /></div>
						{ props.headerChild &&
							<div className="header-child">{props.headerChild}</div>
						}
					</div>
				</Header>
			}
			<Content id="content">{props.children}</Content>
			{ !props.hideFooter &&
				<Footer id="footer">{props.footerChild}</Footer>
			}
		</Container>
	);
}