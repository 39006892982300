import React, { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getUser, setUser, hasCapability } from 'helpers/user';
import useStore from "store";

import PushHandler from 'components/PushHandler';

import Login from "pages/Login";
import Logout from "pages/Logout";
import Dashboard from "pages/Dashboard";
import TankerTrucks from "pages/TankerTrucks";
import TankerTrucksCheck from "pages/TankerTrucksCheck";
import TankerTrucksCheckManualData from "pages/TankerTrucksCheck/ManualData";
import TankerTrucksCheckPhotos from "pages/TankerTrucksCheck/Photos";
import TankerTrucksOnProgress from "pages/TankerTrucksCheck/OnProgress";
import ChatSelector from "pages/ChatSelector";
import Chat from "pages/Chat";
import PricesCheck from "pages/PricesCheck";
import PricesControl from "pages/PricesControl";
import PricesControlForm from "pages/PricesControlForm";
import Supplies from "pages/Supplies";
import SuppliesForm from "pages/SuppliesForm";
import SuppliesOrdersList from "pages/SuppliesOrdersList";
import SuppliesBCOrdersTCForm from "pages/SuppliesBCOrdersTCForm";
import ConteosList from "pages/ConteosList";
import ConteosForm from "pages/ConteosForm";

const MainContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
`; 

function App() {
	const location = useLocation();

    const forceReload = useStore(state => state.forceReload);
    const debug = useStore(state => state.debug);

	// Axios global
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getUser()?.token;
    axios.defaults.baseURL = debug ? '/' : 'https://panel.plenoilapp.com/';
    
    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error
        if ( error.response && error.response.status === 401 ) {
            setUser(null, true);
        }

        // Default
        return Promise.reject(error);
    });

    // Get user & community and update
    useEffect(() => {
    	const updateLoggedUser = async () => {
    		// Get logged user
	    	let user = getUser();
	    	if ( !user || !user.id ) return;

			// Fix: delete token to compare
			let userToCompare = {...user};
			delete userToCompare.token;

	    	// Get from server
	    	let user_from_server = null;
	    	let getUserFromServer = () => {
	    		return axios.get('/api/get-authuser-data').then((response) => {
					user_from_server = response.data;
			  	});
	    	}
	    	await getUserFromServer();

	    	// Compare and update
	    	if ( JSON.stringify(userToCompare) !== JSON.stringify(user_from_server) ) {
	    		setUser({...user, ...user_from_server}, true);
	    	}
    	} 
    	updateLoggedUser();
    }, [forceReload]);
	
	return (
		<MainContainer id="main-container" forceReload={forceReload}>
			{ (!getUser() && location.pathname !== '/login') &&
				<Navigate to="/login" />
			}

			{ getUser() && <PushHandler /> }

			<Routes>
				<Route path="/" element={<Dashboard />} />
				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />
				

				 { hasCapability('app_tankertrucks') &&
					<React.Fragment>
						<Route path="tankertrucks" element={<TankerTrucks />} />
						<Route path="tankertrucks/check/:id" element={<TankerTrucksCheck />} />
						<Route path="tankertrucks/check/manual-data/:id" element={<TankerTrucksCheckManualData />} />
						<Route path="tankertrucks/check/photos/:id" element={<TankerTrucksCheckPhotos />} />
						<Route path="tankertrucks/check/onprogress/:id" element={<TankerTrucksOnProgress />} />
					</React.Fragment>
				}

				{ hasCapability('app_prices') &&
					<React.Fragment>
						<Route path="pricescheck" element={<PricesCheck />} />
						<Route path="pricescontrol" element={<PricesControl />} />
						<Route path="pricescontrol/:id" element={<PricesControlForm />} />
					</React.Fragment>
				}

				{ hasCapability('app_supplies') &&
					<React.Fragment>
						<Route path="supplies" element={<Supplies />} />
						<Route path="supplies/new-order" element={<SuppliesForm />} />
						<Route path="supplies/orders-list" element={<SuppliesOrdersList />} />
						<Route path="supplies/bc-ordertc/:id" element={<SuppliesBCOrdersTCForm />} />
					</React.Fragment>
				}

				{ hasCapability('app_conteos') &&
					<React.Fragment>
						<Route path="conteos/list" element={<ConteosList />} />
						<Route path="conteos/form/:id" element={<ConteosForm />} />
					</React.Fragment>
				}

				<Route path="chatselector" element={<ChatSelector />} />
				<Route path="chat/:channel" element={<Chat />} />

			</Routes>
			<ToastContainer
				position="bottom-right"
				autoClose={2000}
			/>
		</MainContainer>
	);
}

export default App;