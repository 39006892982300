import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useNavigate }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { assets } from 'helpers/generic';
import { hasCapability } from 'helpers/user';
import { default as ChatService } from 'services/Chat';

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-orange);
	border-radius: 50%;
	font-size: 20px;
	margin-bottom: 20px;
`;

const SelectButton = styled.button` 
	border: 0;
	background: transparent;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 80px;
		height: 80px;
		margin: 10px;
	}

	span {
		width: 80px;
		display: block;
	}

	i {
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--plenoil-blue);
		color: white;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		font-size: 12px;
	}

	margin-bottom: 10px;
`;

export default function ChatSelector() {
	let navigate = useNavigate();

	const [stations, setStations] = useState([]);

	useEffect(() => {
		const getData = async () => {
			let data = await ChatService.getStations();
			setStations(data);
		};

		let getDataInterval = setInterval(() => {
			getData();
		}, 5000);
		getData();

		return function cleanup() {
			ChatService.cancel();
			clearInterval(getDataInterval);
		}
	}, []);

	let cisternas_pending_messages = stations?.filter(el => el.channel.id === 'tankertrucks')[0]?.unread ?? 0;
	let precios_pending_messages = stations?.filter(el => el.channel.id === 'prices')[0]?.unread ?? 0;
	let supplies_pending_messages = stations?.filter(el => el.channel.id === 'supplies')[0]?.unread ?? 0;

	return (
		<LoggedLayout hideFooter={true}>
			<Wrapper>
				<CloseButton onClick={() => navigate('/')}>
					<i className="bi bi-chevron-left"></i>
				</CloseButton>

				{ hasCapability('app_tankertrucks') &&
					<SelectButton onClick={() => navigate('/chat/tankertrucks')}>
						<img src={assets('/img/cisternas.svg')} alt="Cisternas" />
						<span>CISTERNAS</span>
						{cisternas_pending_messages > 0 && <i>{cisternas_pending_messages}</i>}
					</SelectButton>
				}

				{ hasCapability('app_prices') &&
					<SelectButton onClick={() => navigate('/chat/prices')}>
						<img src={assets('/img/controlprecios.svg')} alt="Chat" />
						<span>PRECIOS</span>
						{precios_pending_messages > 0 && <i>{precios_pending_messages}</i>}
					</SelectButton>
				}

				{ hasCapability('app_supplies') &&
					<SelectButton onClick={() => navigate('/chat/supplies')}>
						<img src={assets('/img/suministros.svg')} alt="Chat" />
						<span>SUMINISTROS</span>
						{supplies_pending_messages > 0 && <i>{supplies_pending_messages}</i>}
					</SelectButton>
				}
			</Wrapper>
		</LoggedLayout>
	);
}


