import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useLocation }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as TankerTrucksService } from 'services/TankerTrucks';
import { toast } from 'react-toastify';
import resizeImage from 'resize-image';

const BackButton = styled.button`
	width: 30px;
	height: 30px;
	position: absolute;
	top: 10px;
	z-index: 2;
	left: 50%;
	margin-left: -15px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 4px solid var(--plenoil-orange);
	color: black;
	background: lightgray;
	border-radius: 50%;
	font-size: 14px;

	i {

		&::before {
			-webkit-text-stroke: 1px;
		}
	}
`;

const Wrapper = styled.div`
	position: absolute;
	top: 25px;
	width: 100%;
	height: 100%;
	background: var(--plenoil-orange);
	color: white;
	overflow-y: auto;

	.page-title {
		margin-top: 30px;
		text-align: center;
		font-size: 18px;
	}

	#wait-icon {
		margin-top: 120px;
		text-align: center;
		font-size: 18px;
		line-height: 20px;

		i {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 30px;
			color: var(--plenoil-black);
			background: white;
			border-radius: 50%;
			padding: 10px;
			width: 70px;
			height: 70px;
			margin: 10px auto;
		}
	}

	#close-btn {
		margin: 0 auto;
		margin-top: 50px;
		display: block;
		
		background: var(--plenoil-orange);
		color: white;
		border: 1px solid white;
		border-radius: 45px;
		width: 150px;
		font-size: 20px;
		outline: 0 !important;
		position: relative;
		text-transform: uppercase;
	}

`;

export default function TankerTrucksCheckPhotos() {
	let navigate = useNavigate();
	let params = useParams();

	let id = params.id;

	let [tankerTruck, setTankerTruck] = useState(null);
	let [photosProcessing, setPhotosProcessing] = useState(true);

	useEffect(() => {		
		if ( !id ) return;

		const getData = async () => {
			let data = await TankerTrucksService.getCurrentById(id, {with_photos_urldata: true});
			if ( !data?.id || data?.status === 5 ) {
				navigate('/');
				return;
			}

			setTankerTruck(data);
			setPhotosProcessing(data?.photos && data.photos.filter(p => p.ocr_at === null).length > 0);
		};
		getData();

		return function cleanup() {
			TankerTrucksService.cancel();
		}
	}, [navigate, id]);

	useEffect(() => {
		if ( photosProcessing ) return;

		navigate('/tankertrucks');
	}, [navigate, photosProcessing])
	
	return (
		<LoggedLayout hideFooter={true}>
			<BackButton id="back-button" onClick={() => navigate('/tankertrucks')}>
				<i className="bi bi-chevron-left"></i>
			</BackButton>

			<Wrapper>
				<div className="page-title">
					{tankerTruck?.order ?? ''}
				</div>

				<div id="wait-icon">
					{photosProcessing && (
						<i className="bi bi-hourglass-split"></i>
					)}
					<span>LAS IMÁGENES<br />SE ESTÁN PROCESANDO</span>
				</div>
				
				<button 
					id="close-btn" 
					onClick={() => navigate('/tankertrucks')}
				>
					<span>Cerrar</span>
				</button>
			</Wrapper>
		</LoggedLayout>
	);
}


