import React, { useEffect, useState, memo } from 'react';
import styled from 'styled-components';
import { getUser } from 'helpers/user';
import Stations from 'services/Stations';

const StationNameStyled = styled.div` 
	color: var(--plenoil-blue);
`;

const StationName = memo(() => {
	let [station, setStation] = useState(null);

	useEffect(() => {
		const getData = async () => {
			let station = null;

			// From cache
			let cachedStation = window.sessionStorage.getItem('currentStationCached');
			if ( cachedStation ) station = JSON.parse(cachedStation);
			
			// From server
			if ( !cachedStation ) {
				station = await Stations.get(getUser()?.stations[0] ?? null);	
			}

			// Set
			if ( station ) {
				setStation(station);
				window.sessionStorage.setItem('currentStationCached', JSON.stringify(station));
			}
		};
		getData();

		return function cleanup() {
			Stations.cancel();
		}
	}, []);

	return (
        <StationNameStyled>
			{station?.name}
		</StationNameStyled>
	);
}, (a, b) => {
	return false;
});

export default StationName;