export function assets(asset) {
	return process.env.PUBLIC_URL + asset;
}

export const loader = <img src={assets('/img/loader.gif')} id="loader" alt="loader" />;


export const jsonToArray = (json) => {
	let array = [];
	for(let i in json) array.push({key: i, value: json[i]});
	return array;
}

export const formatNumber = (number, decimals = 0, force_decimals = true) => {
	if ( isNaN(number) ) number = 0;
	else number = parseFloat(number);

	const split = number.toString().split('.');
	if ( split[1] ) {
		let integer = parseInt(split[1]);
		if ( integer > 0 ) {
			if ( decimals ) {
				if ( force_decimals ) number = number.toFixed(decimals);
				else {
					let decimalsStr = split[1];
					decimalsStr.replaceAll('0', '');
					number = number.toFixed(decimalsStr.length > decimals ? decimals : decimalsStr.length);
				}
			}
		}
		else number = parseInt(number);
	} else {
		number = parseInt(number);
	}
	return number;
}
export const readableSize = (x) => {
	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let l = 0, n = parseInt(x, 10) || 0;
	while(n >= 1024 && ++l){
		n = n/1024;
	}
	return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const ucfirst = (str) => {
    return str[0].toUpperCase() + str.slice(1);
}