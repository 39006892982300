import React, { useEffect, useState, useRef } from 'react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate, useParams, useLocation }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as TankerTrucksService } from 'services/TankerTrucks';
import { toast } from 'react-toastify';
import resizeImage from 'resize-image';

const BackButton = styled.button`
	width: 30px;
	height: 30px;
	position: absolute;
	top: 10px;
	z-index: 2;
	left: 50%;
	margin-left: -15px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 4px solid var(--plenoil-orange);
	color: black;
	background: lightgray;
	border-radius: 50%;
	font-size: 14px;

	i {

		&::before {
			-webkit-text-stroke: 1px;
		}
	}
`;

const Wrapper = styled.div`
	position: absolute;
	top: 25px;
	width: 100%;
	height: 100%;
	background: var(--plenoil-orange);
	color: white;
	overflow-y: auto;

	.page-title {
		margin-top: 30px;
		text-align: center;
		font-size: 18px;
	}

	.white-square {
		width: 90%;
		margin: 0 auto;
		background: white;
		border-radius: 30px;
		padding: 20px;
		padding-bottom: 30px;
		
		.photos-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}

		.photo-square {
			width: 150px;
			height: 150px;
			border: 2px solid lightgray;
			background: white;
			border-radius: 20px;
			margin-bottom: 10px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin-right: 10px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 20px;
			}

			button {
				position: absolute;
				bottom: 10px;
				right: 10px;
				border: 0;
				padding: 0;
				width: 35px;
				height: 35px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				outline: 0 !important;
				background: var(--plenoil-red);

				i {
					font-size: 18px;
					color: white;
				}
			}

			i {
				font-size: 50px;
				color: gray;
			}
		}

		#send-btn {
			margin: 0 auto;
			margin-top: 30px;
			display: block;
			
			background: var(--plenoil-orange);
			color: white;
			border: 0;
			border-radius: 45px;
			width: 150px;
			font-size: 20px;
			outline: 0 !important;
			position: relative;
			text-transform: uppercase;

			i {
				background: var(--plenoil-blue);
				border-radius: 50%;
				font-size: 25px;
				width: 25px;
				height: 25px;
				margin: 2.5px;
				color: yellow;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				left: 2px;
				top: 1px;
			}

			span {
				display: inline-block;
				font-size: 16px;
				margin-left: 20px;
			}	

			&:disabled {
				opacity: 0.5;
			}
		}

	}
`;

export default function TankerTrucksCheckPhotos() {
	let navigate = useNavigate();
	let params = useParams();

	let forceUpload = (new URLSearchParams(useLocation().search).get('force_upload') ?? null) === 'true';

	let id = params.id;

	const inputFileRef = useRef(null);

	let [tankerTruck, setTankerTruck] = useState(null);
	let [currentPhoto, setCurrentPhoto] = useState(null);
	let [photos, setPhotos] = useState([]);
	let [loading, setLoading] = useState(false);
	let [dataSentOk, setDataSentOk] = useState(false);

	useEffect(() => {		
		if ( !id ) return;

		const getData = async () => {
			let data = await TankerTrucksService.getCurrentById(id, {with_photos_urldata: true});
			if ( !data?.id || data?.status === 5 ) {
				navigate('/');
				return;
			}
			
			setTankerTruck(data);
			// setPhotos(data?.photos?.map(el => el.photo_urldata) ?? []);
			setDataSentOk(!forceUpload && data?.photos && data.photos.length > 0 && data.photos.filter(p => p.ocr_at === null).length === data.photos.length);
		};
		getData();

		return function cleanup() {
			TankerTrucksService.cancel();
		}
	}, [navigate, id, forceUpload]);

	useEffect(() => {
		if ( !dataSentOk ) return;

		navigate('/tankertrucks/check/onprogress/' + tankerTruck?.id);
	}, [navigate, dataSentOk])

	const takePicture = async (number) => {
		// const image = await Camera.getPhoto({
		// 	quality: 90,
		// 	allowEditing: false,
		// 	resultType: CameraResultType.DataUrl,
		// 	source: CameraSource.Camera
		// });

		setCurrentPhoto(number);

		inputFileRef.current.value = null;
		inputFileRef.current.click();

		// Change size to image
		// let img = new Image();
		// img.onload = async () => {
		// 	let data = resizeImage.resize(img, 1400, null);

		// 	// Convert to blob
		// 	let blob = await fetch(data).then(res => res.blob());
			
		// 	// Upload
		// 	let result = await TankerTrucksService.uploadPhoto(tankerTruck.id, blob, number);
		// 	if ( result.status !== 1 ) {
		// 		toast.error('Ha ocurrido un error al guardar, por favor inténtelo de nuevo');
		// 		setPhotos(tankerTruck.photos.map((el, idx) => idx+1));
		// 	} else {
		// 		setPhotos(prev => ([...prev, number]));
		// 	}
		// }
		// img.src = image.dataUrl;
	};

	const inputFileHandler = async (event) => {
        let file = event.target.files[0];

        // Check extension
        let nameSplit = file.name.split('.');
        let extension = nameSplit.pop();
        if ( ['jpg', 'jpeg', 'png', 'bmp', 'gif'].indexOf(extension) === -1 ) {
            toast.error('El fichero de imágen tiene un formato inválido');
            return;
        }

        // Read file
        var reader = new FileReader();
        reader.onload = () => {            
            var dataUrl = reader.result;
            
            // Change size to image
            let img = new Image();
            img.onload = async () => {
                // let data = resizeImage.resize(img, 1410, null, resizeImage.JPEG);
                let data = resizeImage.resize(img, 2000, null, resizeImage.JPEG);

				// Update state
				let newPhotos = [...photos];
				newPhotos[currentPhoto] = data;
				setPhotos(newPhotos);
            }
            img.src = dataUrl;
        };
        reader.readAsDataURL(file);
    }

	const removePhoto = (e, idx) => {
		e.stopPropagation();

		let newPhotos = [...photos];
		newPhotos.splice(idx, 1);
		setPhotos(newPhotos);
	}

	const uploadPhotos = async () => {
		setLoading(true);

		let result = await TankerTrucksService.uploadPhotos(tankerTruck.id, photos);
		if ( result.status !== 1 ) {
			toast.error('Ha ocurrido un error al guardar, por favor inténtelo de nuevo');
		} else {
			setDataSentOk(true);
		}

		setLoading(false);
	}

	return (
		<LoggedLayout hideFooter={true}>
			<BackButton id="back-button" onClick={() => navigate(forceUpload ? '/tankertrucks' : '/tankertrucks/check/manual-data/' + tankerTruck?.id)}>
				<i className="bi bi-chevron-left"></i>
			</BackButton>

			<Wrapper>
				<div className="page-title">
					{tankerTruck?.date ? moment(tankerTruck.date).format('DD-MM-YYYY') : ''}
				</div>
				
				<div className="white-square">
					<div className="photos-wrapper">
						{[0, 1].map((i) => (
							<div className="photo-square" key={i} onClick={() => !dataSentOk ? takePicture(i) : null}>
								{photos[i] ?
									<>
										<img src={photos[i]} alt="Foto" />
										{!dataSentOk &&
											<button onClick={(e) => removePhoto(e, i)}>
												<i className="bi bi-trash"></i>
											</button>
										}
									</>
									:
									<i className="bi bi-plus"></i>
								}
							</div>
						))}
					</div>
				
					<button 
						id="send-btn" 
						onClick={uploadPhotos} 
						disabled={dataSentOk || photos.length === 0}>
						<i className="bi bi-check"></i>
						<span>Enviar</span>
					</button>
				</div>
			</Wrapper>

			<input type="file" ref={inputFileRef} className="d-none" onChange={inputFileHandler} />
		</LoggedLayout>
	);
}


