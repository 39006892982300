import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { assets } from 'helpers/generic';
import { default as SuppliesService } from 'services/Supplies';
import moment from 'moment';

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
`;

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-orange);
	border-radius: 50%;
	font-size: 20px;
	margin-bottom: 20px;
`;

const OrderRow = styled.button` 
	border: 0;
	background: transparent;
	width: 100%;
	display: flex;
	flex-direction: ${props => props.flexDirection ?? 'column'};
	align-items: center;
	justify-content: center;

	img {
		width: 50px;
		height: 50px;
		margin-right: 5px;
	}

	.number {
	}

	.date {
		font-size: 13px;

		span {
			color: gray;
		}
	}

	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--plenoil-blue-light);
`;

const SelectedOrder = styled.div` 
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.5);

	.inner {
		background: var(--plenoil-background-gray);
		box-shadow: 0px 0px 5px rgba(1,1,1,0.5);
		border-radius: 5px;
		width: 90%;
		height: 90%;
		max-width: 350px;
		padding: 20px;
		position: relative;
		display: flex;
		flex-direction: column;

		button {
			border: 0;
			width: 25px;
			height: 25px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			background: var(--plenoil-red);
			color: white;
			border-radius: 50%;
			position: absolute;
			top: -7.5px;
			right: -7.5px;
		}

		h1 {
			text-align: center;
			font-size: 18px;
		}
		
		.lines {
			overflow: auto;
		}

		.line {
			margin-bottom: 10px;

			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				line-height: 90%;
				text-align: left;

				span {
					display: block;
					width: 30px; 
					min-width: 30px;
				}

				i {
					font-style: normal;
					text-align: left;
					width: 100%;
				}

				small {
					color: gray;
					font-size: 12px;
					padding-left: 5px;
					width: 80px;
					text-align: right;
				}
			}

			.qty {
				padding-left: 30px;
			}
		}
	}
`;

export default function Supplies() {
	let navigate = useNavigate();

	let [orders, setOrders] = useState([]);
	let [selectedOrder, setSelectedOrder] = useState(null);

	useEffect(() => {
		const getOrders = async () => {
			let orders = await SuppliesService.getOrders(10, true);
			setOrders(orders);
		}
		getOrders();
	}, []);

	return (
		<LoggedLayout hideFooter={true}>
			<Wrapper>
				<CloseButton onClick={() => navigate('/supplies')}>
					<i className="bi bi-chevron-left"></i>
				</CloseButton>

				<OrderRow flexDirection="row">
					{/* <img src={assets('/img/suministros.svg')} alt="pedidos en curso" /> */}
					<span>HISTORIAL DE INVENTARIOS</span>
				</OrderRow>

				{!orders.length &&
					<OrderRow>
						No hay pedidos para mostrar
					</OrderRow>
				}

				{orders.length > 0 &&
					<React.Fragment>
						{orders.map((el, idx) => {
							return (
								<OrderRow key={idx} onClick={() => setSelectedOrder(el)}>
									<div className="number">PEDIDO #{el.id}</div>
									<div className="date"><span>Fecha:</span> {moment(el.created_at).format('DD-MM-YYYY')}</div>
								</OrderRow>
							);
						})}
					</React.Fragment>
				}
			</Wrapper>
			{ selectedOrder &&
				<SelectedOrder>
					<div className="inner">
						<button onClick={() => setSelectedOrder(null)}>x</button>
						<h1>PEDIDO #{selectedOrder.id}</h1>
						Fecha: {moment(selectedOrder.created_at).format('DD-MM-YYYY')}
						<hr />
						<div className="lines">
							{selectedOrder.lines.map((el, idx) => {
								return (
									<div className="line">
										<div className="top">
											<span>{idx+1}.</span> 
											<i>{el.name}&nbsp;</i> 
											<small>{el.code}</small>
										</div>
										<div className="qty">{el.qty} UNIDADES</div>
									</div>
								);
							})}
						</div>
					</div>
				</SelectedOrder>
			}
		</LoggedLayout>
	);
}


