import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useNavigate }  from 'react-router-dom';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { hasCapability, getUser } from 'helpers/user';
import { assets } from 'helpers/generic';
import Conteos from 'services/Conteos';

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-orange);
	border-radius: 50%;
	font-size: 20px;
	opacity: 0.7;
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	background: white;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	padding: 20px;

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h1 {
			margin: 0;
			padding: 0;
			font-size: 20px;

			img {
				margin-right: 5px;
			}
		}
	}

	h2.subtitle {
		color: black;
		font-size: 20px;
		margin-bottom: 10px;
		margin-top: 10px;
		text-transform: uppercase;
		font-weight: bold;
		margin-left: 10px;
	}

	table#info {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 15px;

		tbody {

			tr {
				border-bottom: 1px solid #e0e0e0;

				td {
					vertical-align: top;
					padding: 4px 0;

					&:first-of-type {
						width: 50%;
					}

					&.t-title {
						color: #444;
						font-weight: bold;
					}

					&.t-value {
						color: black;
						font-size: 16px;
					}
				}
			}
		}
	}

	#btn-open-map {
		margin-bottom: 5px;
		border-radius: 8px;
		padding: 0px 15px;
		font-size: 12px;
	}

	table#conteo {
		width: 100%;
		--font-size: 18px;
		font-size: var(--font-size);

		tbody {

			tr {

				&:last-of-type {
					border-bottom: 1px solid #e0e0e0;

					td {
						padding-bottom: 20px;
					}
				}

				td {
					vertical-align: middle;
					padding: 4px 0;

					&:first-of-type {
						width: 50%;
					}

					&.t-title {
						color: #4d669c;
						font-weight: bold;
						text-align: center;
					}

					&.t-value {
						color: black;
						font-size: 16px;

						input {
							display: block;
							margin: 0 auto;
							border-radius: 20px;
							border-color: black;
							text-align: center;
							font-weight: bold;
							color: black;
							padding: 5px 10px;
							min-height: unset;
							width: 150px;
							font-size: var(--font-size);
						}
					}
				}
			}
		}
	}

	table#prices {
		margin-top: 10px;
		width: 100%;
		--font-size: 18px;
		font-size: var(--font-size);

		tbody {

			tr {

				&:last-of-type {
					border-bottom: 1px solid #e0e0e0;

					td {
						padding-bottom: 20px;
					}
				}

				td {
					vertical-align: middle;
					padding: 4px 0;

					&:first-of-type {
						width: 50%;
					}

					&.t-title {
						color: #444;
						font-weight: bold;
						text-align: center;
					}

					&.t-value {
						color: black;
						font-size: 16px;

						input {
							display: block;
							margin: 0 auto;
							border-radius: 20px;
							border-color: black;
							text-align: center;
							font-weight: bold;
							color: black;
							padding: 5px 10px;
							min-height: unset;
							width: 150px;
							font-size: var(--font-size);
						}
					}
				}
			}
		}
	}

	textarea {
		display: block;
		margin: 0 auto;
		border-radius: 20px;
		border-color: black;
		font-weight: bold;
		color: black;
		padding: 5px 10px;
		min-height: unset;
		font-size: var(--font-size);
	}

	button#send {
		display: block;
		margin: 0 auto;
		border-radius: 18px;
		margin-top: 30px;
		width: 150px;
		padding: 2px 10px;
	}
`;

export default function ConteosForm() {
	let navigate = useNavigate();

	let [conteo, setConteo] = useState(null);
	let [hasLavadero, setHasLavadero] = useState(false);

	useEffect(() => {
		// Load from session storage. This is needed to prevent reload from API data
		let conteo = window.sessionStorage.getItem('selectedConteo');
		conteo = JSON.parse(conteo);
		if (conteo) {
			setConteo(conteo);
			window.sessionStorage.removeItem('selectedConteo');
		} else navigate('/conteos/list');
	}, [navigate]);

	const openMap = () => {
		let url = `https://www.google.com/maps/search/?api=1&query=${conteo?.competitor?.latitude},${conteo?.competitor?.longitude}`;
		window.open(url, '_blank');
	}

	const setDataField = (field, value) => {
		setConteo({
			...conteo,
			[field]: value
		});
	}

	const setTimesTableField = (index, field, value) => {
		let newTimesTable = conteo.timestable;
		newTimesTable[index][field] = value;
		setConteo({
			...conteo,
			timestable: newTimesTable
		});
	}

	const sendForm = async () => {
		let result = await Conteos.sendForm(getUser()?.stations[0] ?? null, conteo);
		if ( result.status ) {
			toast.success('Conteo enviado correctamente');
			navigate('/conteos/list');
		} else {
			toast.error('Ha ocurrido un error al enviar el conteo, por favor inténtelo de nuevo');
		}
	}
	
	// console.log(conteo);

	return (
		<LoggedLayout hideFooter={true}>
			<Wrapper>
				<div className="top">
					<CloseButton onClick={() => navigate('/conteos/list')}>
						<i className="bi bi-chevron-left"></i>
					</CloseButton>
					<h1>
						<img src={assets('/img/conteo-icono.svg')} alt="Conteo" width="30" />
						Conteos
					</h1>
				</div>

				<table id="info">
					<tbody>
						<tr>
							<td className="t-title">Competencia:</td>
							<td className="t-value fw-bold">
								{conteo?.competitor?.name ?? ''}
							</td>
						</tr>
						<tr>
							<td className="t-title">Dirección:</td>
							<td className="t-value">
								{conteo?.competitor?.address ?? ''}

								<button className="btn btn-sm btn-plenoil-primary" id="btn-open-map" onClick={openMap}>VER EN MAPA</button>
							</td>
						</tr>
						<tr>
							<td className="t-title">Día:</td>
							<td className="t-value fw-bold">
								{conteo?.date_execution ? moment(conteo.date_execution).format('DD/MM/YYYY') : ''}
							</td>
						</tr>
					</tbody>
				</table>

				<table id="prices">
					<tbody>
						<tr>
							<td className="t-title">Precio GOA</td>
							<td className="t-value fw-bold">
								<input 
									type="number" 
									className="form-control form-control-sm no-arrows" 
									onFocus={(e) => e.target.select()}
									onChange={(e) => setDataField('price_goa', e.target.value)} 
									value={conteo?.price_goa ?? ''} 
								/>
							</td>
						</tr>
						<tr>
							<td className="t-title">Precio SP95</td>
							<td className="t-value fw-bold">
								<input 
									type="number" 
									className="form-control form-control-sm no-arrows"
									onFocus={(e) => e.target.select()}
									onChange={(e) => setDataField('price_sp95', e.target.value)} 
									defaultValue={conteo?.price_sp95 ?? ''} 
								/>
							</td>
						</tr>
					</tbody>
				</table>

				<h2 className="subtitle d-inline-block">¿Tiene lavadero?</h2>
				<div className="form-check form-switch d-inline-block ms-3 mb-3">
					<input className="form-check-input" type="checkbox" role="switch" id="hasLavaderoSwitch" onChange={(e) => setHasLavadero(e.target.checked)} checked={hasLavadero} />
					<label className="form-check-label" htmlFor="hasLavaderoSwitch">{hasLavadero ? 'SI' : 'NO'}</label>
				</div>

				<h2 className="subtitle">Conteo estación</h2>
				<table id="conteo">
					<tbody>
						{conteo?.timestable?.length > 0 &&
							conteo.timestable.map((time, index) => {
								return (
									<tr key={index}>
										<td className="t-title">{moment(time.start_at, 'HH:mm:ss').format('HH:mm')} / {moment(time.end_at, 'HH:mm:ss').format('HH:mm')}</td>
										<td className="t-value fw-bold">
											<input 
												type="number" 
												className="form-control form-control-sm no-arrows" 
												value={time.count ?? ''} 
												onChange={(e) => setTimesTableField(index, 'count', e.target.value)}
											/>
										</td>
									</tr>
								);
							})
						}
					</tbody>
				</table>

				{ hasLavadero &&
					<>
						<h2 className="subtitle">Conteo lavadero</h2>
						<table id="conteo">
							<tbody>
								{conteo?.timestable?.length > 0 &&
									conteo.timestable.map((time, index) => {
										return (
											<tr key={index}>
												<td className="t-title">{moment(time.start_at, 'HH:mm:ss').format('HH:mm')} / {moment(time.end_at, 'HH:mm:ss').format('HH:mm')}</td>
												<td className="t-value fw-bold">
													<input 
														type="number" 
														className="form-control form-control-sm no-arrows" 
														value={time.count_car_wash ?? ''} 
														onChange={(e) => setTimesTableField(index, 'count_car_wash', e.target.value)}
													/>
												</td>
											</tr>
										);
									})
								}
							</tbody>
						</table>
					</>
				}

				<h2 className="subtitle">Observaciones</h2>
				<textarea 
					className="form-control" 
					rows="3"
					value={conteo?.observations ?? ''}
					onChange={(e) => setDataField('observations', e.target.value)}
				></textarea>

				<button id="send" className="btn btn-plenoil-primary" onClick={sendForm} disabled={!hasCapability('app_conteos', 'add')}>ENVIAR</button>
			</Wrapper>
		</LoggedLayout>
	);
}


