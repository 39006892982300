import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useNavigate, useParams }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as TankerTrucksService } from 'services/TankerTrucks';
import moment from 'moment';
import { toast } from 'react-toastify';
import { assets } from 'helpers/generic';


const Wrapper = styled.div`
	position: absolute;
	top: 25px;
	width: 100%;
	height: 100%;
	background: var(--plenoil-orange);
	color: white;
	overflow-y: auto;
`;

const BackButton = styled.button`
	width: 30px;
	height: 30px;
	position: absolute;
	top: 10px;
	z-index: 2;
	left: 50%;
	margin-left: -15px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 4px solid var(--plenoil-orange);
	color: black;
	background: lightgray;
	border-radius: 50%;
	font-size: 14px;

	i {

		&::before {
			-webkit-text-stroke: 1px;
		}
	}
`;

const TruckIcon = styled.div`
	margin-top: 30px;
	margin-bottom: 20px;
	text-align: center;

	img {
		width: 50px;
		display: block;
		margin: 0 auto;
	}
`;

const Observations = styled.div`
	text-align: center;
	color: white;
	width: 200px;
	margin: 0 auto;
	font-size: 16px;
	line-height: 17px;
	margin-bottom: 20px;

	&:empty {
		margin-bottom: 10px;
	}
`;

const ChecksRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	font-size: 20px;
	${props => props.disabled ? 'opacity: 0.4; pointer-events: none;' : ''}

	span {
		display: inline-block;
		margin: 0 15px;
		width: 120px;
		text-align: center;
	}
`;

const CheckButton = styled.button`
	background: ${props => props.active ? props.activeBackground : 'var(--bs-gray-200)'};
	color: ${props => props.active ? 'var(--plenoil-yellow)' : 'var(--bs-gray-500)'};
	${props => props.disabled ? 'opacity: 0.4; pointer-events: none;' : ''}
	border: 0;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	font-size: 45px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	outline: 0 !important;

	i {
		margin-top: 5px;
	}

	span {
		font-size: 22px;
	}
`;

const IncidenciaWrapper = styled.div`
	margin-bottom: 20px;

	.form-check-input:checked[type=radio] {
		background-color: var(--plenoil-red) !important;
		border-color: var(--plenoil-red) !important;
	}

	.radios {
		width: 170px;
		margin: 0 auto;
		font-size: 20px;

		label {
			display: block;
			user-select: none;
			cursor: pointer;

			input[type=radio] {
				cursor: pointer;
			}
		}
	}

	.cantidad {
		width: 200px;
		margin: 0 auto;

		.cantidad_row {
			display: flex;
			align-items: center;
			margin-top: 10px;

			.cantidad_title {
				width: 60px;
				min-width: 60px;
				max-width: 60px;
				font-size: 18px;
				line-height: 10px;
				white-space: nowrap;
				text-align: right;
			}

			.cantidad_input {
				width: 100%;
				padding-left: 10px;

				input {
					text-align: right;
				}
			}
		}
	}

	.otros {
		width: 250px;
		margin: 10px auto;

		textarea {
			font-size: 14px;
			line-height: 16px;
		}
	}
`;

const DataPhotosButton = styled.div`
	margin-top: 80px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	a {
		color: white;
		text-decoration: none;
	}

	button {
		margin-bottom: 15px;
		width: 60px;
		height: 60px;

		i {
			font-size: 35px;
		}

		margin-bottom: 10px;
	}

	div {
		font-size: 25px;
		line-height: 30px;
	}
`;

export default function TankerTrucksCheck() {
	let navigate = useNavigate();
	let params = useParams();

	let id = params.id;

	let [tankerTruck, setTankerTruck] = useState(null);
	
	let [llegada, setLlegada] = useState(null);
	let [precinto, setPrecinto] = useState(null);
	let [incidencia, setIncidencia] = useState(undefined);

	let [incidenciaType, setIncidenciaType] = useState(null);
	let [incidenciaDiesel, setIncidenciaDiesel] = useState(null);
	let [incidenciaSp95, setIncidenciaSp95] = useState(null);
	let [incidenciaOthersText, setIncidenciaOthersText] = useState(null);

	useEffect(() => {		
		if ( !id ) return;

		const getData = async () => {
			let data = await TankerTrucksService.getCurrentById(id);
			if ( !data?.id ) {
				navigate('/');
				return;
			}
			
			setTankerTruck(data);
			setLlegada(data.date_in ? 1 : null);
			if ( data.seal !== null ) setPrecinto(data.seal ? 1 : 0);
			
			if ( data.incidence ) {
				setIncidencia(1);
				setIncidenciaType(data.incidence.type);
				setIncidenciaDiesel(data.incidence?.diesel_liters);
				setIncidenciaSp95(data.incidence?.sp95_liters);
				setIncidenciaOthersText(data.incidence?.message);
			} else {
				setIncidencia(data.seal !== null ? null : undefined); // Fix: if we have seal, then we set incidence to null instead of undefined
			}
		};
		getData();

		return function cleanup() {
			TankerTrucksService.cancel();
		}
	}, [navigate, id]);

	useEffect(() => {
		if ( !tankerTruck?.id ) return;

		let checkStatusInterval = setInterval(async () => {
			let ttcheck = await TankerTrucksService.getCurrentById(tankerTruck.id);
			if ( !ttcheck || ttcheck?.status === 5 ) navigate('/');

			// Update incidence status
			if ( ttcheck && ttcheck.incidence ) {
				setTankerTruck((prev) => ({
					...prev,
					incidence: ttcheck.incidence
				}));
			}
		}, 5000);

		return function cleanup() {
			clearInterval(checkStatusInterval);
		}
	}, [tankerTruck?.id, navigate])

	const updatePrecinto = async (status) => {
		let result = await TankerTrucksService.updateField(tankerTruck.id, 'seal', status);
		if ( result.status !== 1 ) {
			toast.error('Ha ocurrido un error al guardar, por favor inténtelo de nuevo');
			setPrecinto(tankerTruck.seal);
		} else {
			setPrecinto(status);
			setTankerTruck((prev) => ({...prev, seal: status})); // Update tanker truck
		}
	}

	const updateDateIn = async (status) => {
		let date = moment().format('YYYY-MM-DD HH:mm');

		let result = await TankerTrucksService.updateField(tankerTruck.id, 'date_in', status ? date : null);
		if ( result.status !== 1 ) {
			toast.error('Ha ocurrido un error al guardar, por favor inténtelo de nuevo');
			setLlegada(null);
		} else {
			setLlegada(status);
			setTankerTruck((prev) => ({...prev, date_in: status})); // Update tanker truck
		}
	}

	const updateIncidenciaStatus = async (status) => {
		let result = await TankerTrucksService.updateField(tankerTruck.id, 'incidence', null);
		if ( result.status !== 1 ) {
			toast.error('Ha ocurrido un error al guardar, por favor inténtelo de nuevo');
			setIncidencia(tankerTruck.incidence ? 1 : 0);
		} else {
			setIncidencia(status);
			if ( !status ) setIncidenciaType(null);
			setTankerTruck((prev) => ({...prev, incidence: null})); // Update tanker truck
		}
	}

	const updateIncidencia = async () => {
		// Check tankertruck is loaded
		if ( !tankerTruck?.id ) return;

		let toSave = {
			type: incidenciaType,
		};

		if ( incidenciaType === 'quantities' || incidenciaType === 'excess' ) {
			toSave.sp95_liters = incidenciaSp95;
			toSave.diesel_liters = incidenciaDiesel;
		}

		if ( incidenciaType === 'others' ) {
			toSave.message = incidenciaOthersText;
		}

		let result = await TankerTrucksService.updateIncidence(tankerTruck.id, toSave);
		if ( result.status !== 1 ) {
			toast.error('Ha ocurrido un error al guardar, por favor inténtelo de nuevo');
			setIncidenciaType(tankerTruck.incidence?.type);
		} else {
			setTankerTruck((prev) => ({...prev, incidence: toSave})); // Update tanker truck
		}
	}

	return (
		<LoggedLayout hideFooter={true}>
			<BackButton onClick={() => navigate('/tankertrucks')}>
				<i className="bi bi-chevron-left"></i>
			</BackButton>

			<Wrapper>
				<TruckIcon>
					<img src={assets('/img/cisternas-icono.svg')} alt="camion" />
					COMPROBACIÓN DE CISTERNA
				</TruckIcon>

				{ tankerTruck?.observations &&
					<Observations>{tankerTruck.observations}</Observations>
				}

				<ChecksRow>
					<CheckButton active={llegada === 0} activeBackground={'var(--plenoil-red)'} onClick={() => updateDateIn(0)}><i className="bi bi-x"></i></CheckButton>
					<span>LLEGADA</span>
					<CheckButton active={llegada === 1} activeBackground={'var(--plenoil-blue)'} onClick={() => updateDateIn(1)}><i className="bi bi-check"></i></CheckButton>
				</ChecksRow>

				<ChecksRow disabled={llegada === null}>
					<CheckButton active={precinto === 0} activeBackground={'var(--plenoil-red)'} onClick={() => updatePrecinto(0)}><i className="bi bi-x"></i></CheckButton>
					<span>PRECINTO</span>
					<CheckButton active={precinto === 1} activeBackground={'var(--plenoil-blue)'} onClick={() => updatePrecinto(1)}><i className="bi bi-check"></i></CheckButton>
				</ChecksRow>

				<ChecksRow disabled={precinto === null}>
					<CheckButton active={incidencia === 0 || incidencia === null} activeBackground={'var(--plenoil-red)'} onClick={() => updateIncidenciaStatus(0)}><span>NO</span></CheckButton>
					<span>INCIDENCIA</span>
					<CheckButton active={incidencia === 1} activeBackground={'var(--plenoil-blue)'} onClick={() => updateIncidenciaStatus(1)}><span>SI</span></CheckButton>
				</ChecksRow>

				{ (incidencia === 1) &&
					<IncidenciaWrapper>
						<div className="radios">
							{ (!incidenciaType || incidenciaType === 'excess') &&
								<label><input type="radio" className="form-check-input" checked={incidenciaType === 'excess'} onChange={() => setIncidenciaType('excess')} /> POR EXCESO</label>
							}
							{ (!incidenciaType || incidenciaType === 'quantities') &&
								<label><input type="radio" className="form-check-input" checked={incidenciaType === 'quantities'} onChange={() => setIncidenciaType('quantities')} /> POR CANTIDAD</label>
							}
							{ (!incidenciaType || incidenciaType === 'address') &&
								<label><input type="radio" className="form-check-input" checked={incidenciaType === 'address'} onChange={() => setIncidenciaType('address')} /> OTRA DIRECCIÓN</label>
							}
							{ (!incidenciaType || incidenciaType === 'others') &&
								<label><input type="radio" className="form-check-input" checked={incidenciaType === 'others'} onChange={() => setIncidenciaType('others')} /> OTROS</label>
							}
						</div>

						{ incidenciaType === 'excess' &&
							<div className="cantidad">

								<div className="cantidad_row">
									<div className="cantidad_title">
										GOA
									</div>
									<div className="cantidad_input">
										<input type="number" className="form-control form-control-sm no-arrows" value={incidenciaDiesel ?? ''} onChange={(e) => setIncidenciaDiesel(e.target.value)} />
									</div>
								</div>

								<div className="cantidad_row">
									<div className="cantidad_title">
										SP95
									</div>
									<div className="cantidad_input">
										<input type="number" className="form-control form-control-sm no-arrows" value={incidenciaSp95 ?? ''} onChange={(e) => setIncidenciaSp95(e.target.value)} />
									</div>
								</div>
							</div>
						}

						{ incidenciaType === 'quantities' &&
							<div className="cantidad">

								<div className="cantidad_row">
									<div className="cantidad_title">
										GOA
									</div>
									<div className="cantidad_input">
										<input type="number" className="form-control form-control-sm no-arrows" value={incidenciaDiesel ?? ''} onChange={(e) => setIncidenciaDiesel(e.target.value)} />
									</div>
								</div>

								<div className="cantidad_row">
									<div className="cantidad_title">
										SP95
									</div>
									<div className="cantidad_input">
										<input type="number" className="form-control form-control-sm no-arrows" value={incidenciaSp95 ?? ''} onChange={(e) => setIncidenciaSp95(e.target.value)} />
									</div>
								</div>
							</div>
						}

						{ incidenciaType === 'others' &&
							<div className="otros">
								<textarea 
									className="form-control" 
									rows="5" 
									maxLength="1000" 
									value={incidenciaOthersText ?? ''} 
									onChange={(e) => setIncidenciaOthersText(e.target.value)} 
								/>
							</div>
						}

						{ (incidenciaType !== tankerTruck.incidence?.type && tankerTruck.incidence?.approved === undefined) &&
							<div className="text-center mt-2">
								<button className="btn btn-sm btn-plenoil-primary" onClick={updateIncidencia}>Confirmar</button>
							</div>
						}
						{ (incidenciaType && tankerTruck.incidence?.approved === undefined) && <div className="text-center text-plenoil-primary mt-2">Esperando respuesta... <i className="bi bi-clock"></i></div>}
						{ (incidenciaType && tankerTruck.incidence?.approved === "1") && <div className="text-center mt-2 text-plenoil-primary">Aprobada</div>}
						{ (incidenciaType && tankerTruck.child) && <div className="text-center text-plenoil-primary">con desvío a {tankerTruck.child?.station?.name}</div>}
						{ (incidenciaType && tankerTruck.incidence?.approved === "0") && <div className="text-center mt-2 text-danger">Denegada</div>}
					</IncidenciaWrapper>
				}

				{ incidencia !== undefined &&
					<DataPhotosButton>
						<NavLink to={'/tankertrucks/check/manual-data/' + tankerTruck?.id}>
							<CheckButton active={true} activeBackground={'var(--plenoil-blue)'}><i className="bi bi-camera"></i></CheckButton>
							<div>
								INTRODUCIR DATOS<br />
								Y FOTOS
							</div>
						</NavLink>
					</DataPhotosButton>
				}
			</Wrapper>
		</LoggedLayout>
	);
}


