import axios from 'axios';

export default class Users {

	static axiosCancelToken = null;
	
	static updatePushToken = (token, device) => {
		this.newAxiosCancelToken();

		return axios.post('api/users/update-push-token', {
			token: token,
			device: device
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}