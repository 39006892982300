import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useNavigate }  from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as PricesService } from 'services/Prices';

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	color: white;
`;

const BackButton = styled.button`
	width: 30px;
	height: 30px;
	position: absolute;
	top: -40px;
	left: 30px;
	margin-left: -15px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-black);
	border-radius: 50%;
	font-size: 20px;
`;


const PricesCheckStyled = styled.div`
	background: var(--plenoil-red);
	width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .prices-check-wrapper {
        display: flex;
    }

    .prices {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
            padding: 0px 20px;
            width: 120px;
            color: white;
            font-size: 12px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 20px;
                margin-left: 20px;
            }
        }

        .goa {
            background: var(--plenoil-black);
        }

        .sp95 {
            background: var(--plenoil-green);
        }
    }
`;

const ChecksRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	font-size: 20px;
    color: var(--bs-dark);
	${props => props.disabled ? 'opacity: 0.4; pointer-events: none;' : ''}

	span {
		display: inline-block;
		margin: 0 15px;
		width: 120px;
		text-align: center;
	}
`;

const CheckButton = styled.button`
	background: ${props => props.active ? props.activeBackground : 'var(--bs-gray-200)'};
	color: ${props => props.active ? 'var(--plenoil-yellow)' : 'var(--bs-gray-500)'};
	${props => props.disabled ? 'opacity: 0.4; pointer-events: none;' : ''}
	border: 0;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	font-size: 45px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	outline: 0 !important;

	i {
		margin-top: 5px;
	}

	span {
		font-size: 22px;
	}
`;

const ButtonFinalize = styled.button`
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    border: 0;
    background: var(--plenoil-blue);
    color: white;
    padding: 5px 22px;
    border-radius: 10px;
    font-size: 18px;

    &:disabled {
        opacity: 0.7;
        background: var(--bs-gray-500);
    }
`;

export default function PricesCheck() {
	let navigate = useNavigate();

    let [pricesCheck, setPricesCheck] = useState(null);

    let [totem, setTotem] = useState(null);
    let [preciario, setPreciario] = useState(null);
    let [surtidor, setSurtidor] = useState(null);
    let [autofuel, setAutofuel] = useState(null);

	useEffect(() => {		
		const getData = async () => {
			let data = await PricesService.getPendingCheck();
            if ( !data.id || moment(data.date).isAfter(moment().format('YYYY-MM-DDTHH:mm:ss')) ) {
                navigate('/');
                return;
            }
			setPricesCheck(data);
		};

		let getPendingCheckInterval = setInterval(() => {
			getData();
		}, 5000);
		getData();

		return function cleanup() {
			clearInterval(getPendingCheckInterval);
			PricesService.cancel();
		}
	}, [navigate]);


	const updatePricesCheck = async () => {
		let result = await PricesService.updateCheckStatuses(pricesCheck.id, {totem_check: totem, preciario_check: preciario, surtidor_check: surtidor, autofuel_check: autofuel});
		if ( result.status !== true ) {
			toast.error('Ha ocurrido un error al guardar, por favor inténtelo de nuevo');
		} else {
            navigate('/');
        }
	}


	return (
		<LoggedLayout hideFooter={true} hideHeader={false}>
			<Wrapper>
				<BackButton onClick={() => navigate('/')}>
					<i className="bi bi-chevron-left"></i>
				</BackButton>

                {pricesCheck &&
                    <PricesCheckStyled>
                        { moment(pricesCheck.date).isSameOrBefore(moment().format('YYYY-MM-DDTHH:mm:ss')) &&
                            <div className="prices-check-wrapper">
                                <div className="prices">
                                    <div className="goa">DIESEL <span>{parseFloat(pricesCheck.goa_price).toFixed(3)}</span></div>
                                    <div className="sp95">SP95 <span>{parseFloat(pricesCheck.sp95_price).toFixed(3)}</span></div>
                                </div>
                            </div>
                        }
                    </PricesCheckStyled>
                }

                <ChecksRow>
					<CheckButton active={totem === 0} activeBackground={'var(--plenoil-red)'} onClick={() => setTotem(0)}><i className="bi bi-x"></i></CheckButton>
					<span>TOTEM</span>
					<CheckButton active={totem === 1} activeBackground={'var(--plenoil-blue)'} onClick={() => setTotem(1)}><i className="bi bi-check"></i></CheckButton>
				</ChecksRow>

                <ChecksRow>
					<CheckButton active={preciario === 0} activeBackground={'var(--plenoil-red)'} onClick={() => setPreciario(0)}><i className="bi bi-x"></i></CheckButton>
					<span>PRECIARIO</span>
					<CheckButton active={preciario === 1} activeBackground={'var(--plenoil-blue)'} onClick={() => setPreciario(1)}><i className="bi bi-check"></i></CheckButton>
				</ChecksRow>

                <ChecksRow>
					<CheckButton active={surtidor === 0} activeBackground={'var(--plenoil-red)'} onClick={() => setSurtidor(0)}><i className="bi bi-x"></i></CheckButton>
					<span>SURTIDOR</span>
					<CheckButton active={surtidor === 1} activeBackground={'var(--plenoil-blue)'} onClick={() => setSurtidor(1)}><i className="bi bi-check"></i></CheckButton>
				</ChecksRow>

                <ChecksRow>
					<CheckButton active={autofuel === 0} activeBackground={'var(--plenoil-red)'} onClick={() => setAutofuel(0)}><i className="bi bi-x"></i></CheckButton>
					<span>APP</span>
					<CheckButton active={autofuel === 1} activeBackground={'var(--plenoil-blue)'} onClick={() => setAutofuel(1)}><i className="bi bi-check"></i></CheckButton>
				</ChecksRow>

                <ButtonFinalize disabled={totem === null || preciario === null || surtidor === null || autofuel === null} onClick={updatePricesCheck}>FINALIZAR</ButtonFinalize>

			</Wrapper>
		</LoggedLayout>
	);
}


