import React from 'react';
import styled from 'styled-components';

const StyledQtySelector = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    
    .overlay {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);

        .inner {
            display: flex;
            background: white;
            width: 200px;
            height: 60px;
            border-radius: 50px;
            box-shadow: 0 0 15px rgba(0,0,0,0.5);

            span {
                display: flex;
                width: 25%;
                justify-content: center;
                align-items: center;
                font-size: 25px;
                cursor: pointer;
                padding: 0 10px;
            }

            input[type=number] {
                width: 100%;
                padding: 0;
                text-align: center;
                font-size: 25px;
                margin: 5px 0;
                box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
                border: 0;
                outline: none;
            }
        }
    }
`;

const QtySelector = (props) => {
    let value = props.value;
    const setValue = props.setValue;
    const close = props.close;

    const closeClickHandler = (e) => {
        if (e.target.classList.contains('overlay')) {
            close();
        }
    }

    const addOne = () => {
        let newValue = null;
        if ( value === null ) value = -1;
        if ( value + 1 > 9999 ) newValue = 9999;
        else newValue = value + 1;
        setValue(newValue);
    }

    const subsOne = () => {
        let newValue = null;
        if ( value === null ) value = 1;
        if ( value - 1 < 0 ) newValue = null;
        else newValue = value - 1;
        setValue(newValue);
    }

    const setNewValue = (value) => {
        let newValue = parseInt(value);
        if ( isNaN(newValue) ) newValue = 0;
        setValue(newValue);
    }

    return (
        <StyledQtySelector id="qty-selector">
            <div className="overlay" onClick={closeClickHandler}>
                <div className="inner">
                    <span><i className="bi bi-dash-circle-fill" onClick={subsOne}></i></span>
                    <input type="number" value={value === null ? '' : value} onClick={(e) => e.target.select()} onChange={(e) => setNewValue(e.target.value)} min="0" max="9999" step="1" className="no-arrows" />
                    <span><i className="bi bi-plus-circle-fill" onClick={addOne}></i></span>
                </div>
            </div>
        </StyledQtySelector>
    );
}

export default QtySelector;