import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import Prices from 'services/Prices';
import { hasCapability } from 'helpers/user';

const PricesCheckStyled = styled.div`
	background: var(--plenoil-red);
	width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .prices-check-wrapper {
        display: flex;
    }

    .prices {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
            padding: 0px 20px;
            width: 120px;
            color: white;
            font-size: 12px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 20px;
                margin-left: 20px;
            }
        }

        .goa {
            background: var(--plenoil-black);
        }

        .sp95 {
            background: var(--plenoil-green);
        }
    }

    .text {
        color: white;
        text-align: center;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        width: 100px;
        height: 100px;
        margin-left: 30px;
        border-radius: 50%;
        background: var(--plenoil-orange);
        border: 0;
        color: white;
        font-size: 15px;
        line-height: 15px;
    }
`;

export default function PricesCheck() {
	let [pricesCheck, setPricesCheck] = useState(null);

	useEffect(() => {
        if ( !hasCapability('app_prices') ) return;

		const getData = async () => {
			let data = await Prices.getPendingCheck();
			setPricesCheck(data);
		};

		let getPendingCheckInterval = setInterval(() => {
			getData();
		}, 5000);
		getData();

		return function cleanup() {
			clearInterval(getPendingCheckInterval);
			Prices.cancel();
		}
	}, []);

	return (
        <React.Fragment>
            {pricesCheck &&
                <PricesCheckStyled>
                    { moment(pricesCheck.date).isSameOrBefore(moment().format('YYYY-MM-DDTHH:mm:ss')) ?
                        <div className="prices-check-wrapper">
                            <div className="prices">
                                <div className="goa">DIESEL <span>{parseFloat(pricesCheck.goa_price).toFixed(3)}</span></div>
                                <div className="sp95">SP95 <span>{parseFloat(pricesCheck.sp95_price).toFixed(3)}</span></div>
                            </div>
                            { hasCapability('app_prices', 'edit') &&
                                <NavLink to="/pricescheck">CONFIRMAR CAMBIO DE PRECIO</NavLink>
                            }
                        </div>
                        :
                        <div className="prices-check-wrapper">
                            <div className="text">
                                CAMBIO DE PRECIO PROGRAMADO
                                <div>{moment(pricesCheck.date).format('DD/MM/YYYY')}</div>
                                <div>{moment(pricesCheck.date).format('HH:mm')}H</div>
                            </div>
                        </div>
                    }
                </PricesCheckStyled>
            }
        </React.Fragment>
	);
}


