import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate, useParams }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { assets } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { default as SuppliesService } from 'services/Supplies';
import QtySelector from './QtySelector';

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-orange);
	border-radius: 50%;
	font-size: 20px;
	margin-left: 10px;
	align-self: start;
	
`;

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	overflow: auto;
	padding-bottom: 20px;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;

	.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(255,255,255,0.3);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		user-select: none;
	}

	#title {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		padding: 0 30px;
		color: black;
		justify-content: center;
	}

	#search-box {
		margin-top: 20px;
		width: 100%;
	}

	#albaran-fields {
		display: flex;
		flex-direction: column;
		align-items: end;
		width: 100%;
		padding: 0 30px;
		margin-top: 20px;
		margin-bottom: 10px;

		div {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 10px;

			label {
				font-size: 14px;
				margin-right: 10px;
				color: black;
			}

			input {
				border: 1px solid lightgray;
				border-radius: 5px;
				padding: 5px;
				font-size: 14px;
				color: black;
				width: 150px;
			}
		}
	}

	#lines-wrapper { 
		padding: 20px 0;
		width: 95%;
		border: 1px solid lightgray;
		border-radius: 10px;
		margin-top: 10px;
		background: #fffaf6;

		.line {
			display: flex;
			color: black;
			background: #f1eeea;

			&:not(:last-of-type) {
				margin-bottom: 15px;	
			}

			div {
				width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				padding-left: 10px;

				small {
					position: absolute;
					top: 0px;
					right: 3px;
					font-size: 8px;
					opacity: 0.8;
				}
			}

			i {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 80px;
				background: #dcddd8;
				font-style: normal;
				cursor: pointer;
			}
		}

		.empty {
			text-align: center;
			color: var(--plenoil-red);
		}
	}

	button#btn-send {
		margin-top: 20px;
	}
`;

const ButtonsSelectorWrapper = styled.div`
	display: flex;
	margin-top: 40px;
`;

const SelectButton = styled.button` 
	border: 0;
	background: transparent;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: 80px;
		height: 80px;
		margin: 10px;
		margin-bottom: 10px;
	}

	span {
		display: block;
	}

	margin-bottom: 10px;
`;

export default function SuppliesForm() {
	let navigate = useNavigate();
	let params = useParams();

	let orderId = params.id;

	let [order, setOrder] = useState(null);
	let [loading, setLoading] = useState(false);
	let [qtySelectorVisible, setQtySelectorVisible] = useState(null);

	useEffect(() => {

		let getOrder = async () => {
			setLoading(true);

			let order = await SuppliesService.getBCOrderToCheck(orderId);
			if ( !order ) {
				navigate(-1);
				toast.error('No se ha encontrado el inventario para revisar');
				return;
			}

			// Fix remove qty to force user to insert qty manually
			order.products = order.products.map((el) => {
				el.qty = null;
				return el;
			});

			setOrder(order);

            setLoading(false);
		}
		getOrder();

		return function cleanup() {
			SuppliesService.cancel();
		}
	}, []);

	useEffect(() => {
		
	}, []);

	const setAlbaran = (value) => {
		setOrder({...order, albaran: value});
	}

	const setAlbaranDate = (value) => {
		setOrder({...order, albaran_date: value});
	}

	const setQty = (idx, qty) => {
		let newProducts = [...order.products];
		newProducts[idx].qty = qty;
		setOrder({...order, products: newProducts});
	}

	const sendForm = async () => {
		let c = window.confirm('¿Seguro que quieres enviar este pedido? Revisalo bien por que una vez enviado no podrás modificarlo.');
		if ( !c ) return;

		setLoading(true);
		let result = await SuppliesService.sendBCOrderToCheck(orderId, {
			products: order.products.map(el => ({id: el.id, qty: el.qty})),
			albaran: order.albaran,
			albaran_date: order.albaran_date
		});
		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Comprobación de inventario enviada');
			navigate('/');
		} else {
			toast.error('Error al enviar los datos');
		}
	}

	let btnSendEnabled = order?.products?.every(el => el.qty !== null) && order?.albaran?.length > 0 && order?.albaran_date?.length > 0;
	
	return (
		<LoggedLayout hideFooter={true}>
			<Wrapper>
				<CloseButton onClick={() => navigate('/supplies')}>
					<i className="bi bi-chevron-left"></i>
				</CloseButton>

	
				{ loading && <div className="loading"><img src={assets('/img/loader.gif')} alt="loading" /></div>}
				<div id="title">
					COMPROBACIÓN DE INVENTARIO
				</div>
				<div id="albaran-fields">
					<div>
						<label>Nº DE ALBARÁN:</label>
						<input type="text" value={order?.albaran ?? ''} onChange={(e) => setAlbaran(e.target.value)} maxLength={100} />
					</div>
					<div>
						<label>FECHA:</label>
						<input type="date" value={order?.albaran_date ?? ''} onChange={(e) => setAlbaranDate(e.target.value)} />
					</div>
				</div>
				<div id="lines-wrapper">
					{order?.products?.map((el, idx) => {
						return (
							<div className="line" key={idx}>
								<div>
									{idx+1}.&nbsp;&nbsp;
									{el.name} 
									{/* <small>{el.code}</small> */}
								</div>
								<i onClick={(e) => setQtySelectorVisible(idx)}>{el.qty ?? '-'}</i>
							</div>
						);
					})}
					{!order &&
						<div className="empty px-2">No se han encontrado ningun pedido para comprobar</div>
					}
				</div>
				<button className="btn btn-plenoil-primary" id="btn-send" disabled={!btnSendEnabled} onClick={sendForm}>Enviar</button>	
			</Wrapper>

			{qtySelectorVisible !== null &&
				<QtySelector 
					value={order.products[qtySelectorVisible]?.qty ?? ''}
					setValue={(qty) => setQty(qtySelectorVisible, qty)}
					close={() => setQtySelectorVisible(null)}
				/>
			}
		</LoggedLayout>
	);
}


