import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import Prices from 'services/Prices';
import { hasCapability } from 'helpers/user';
import TankerTrucks from 'services/TankerTrucks';

const Styled = styled.div`
	background: var(--plenoil-orange);
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        color: white;
        text-decoration: none;

        padding: 10px;

        .pcar {
            font-size: 20px;
            text-align: center;
        }

        .icon {
            width: 45px;
            height: 45px;
            background: var(--plenoil-red);
            color: var(--plenoil-orange);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 10px 0;
            
            i {
                font-size: 45px;
                line-height: 45px;
            }
        }

        .message {
            text-align: center;
            color: white;
            font-size: 15px;
            line-height: 15px;
        }
    }
`;

export default function TankerTrucksWithOcrErrors() {
	let [tankerTruck, setTankerTruck] = useState(null);

	useEffect(() => {
        if ( !hasCapability('app_prices') ) return;

		const getData = async () => {
            let currents = await TankerTrucks.getCurrentsList();

            // FIX: en las current pueden aparecer las que van con retraso, por lo tanto, hay que filtrar las que tengan fotos por que eso significa que son las que se han enviado el ocr y tienen error. Las que no tienen foto simplemente tienen retraso.
            currents = currents.filter(tankertruck => tankertruck.photos.length > 0);

			setTankerTruck(currents ? currents[0] : null);
		};

		let checkInterval = setInterval(() => {
			getData();
		}, 10000);
		getData();

		return function cleanup() {
			clearInterval(checkInterval);
			Prices.cancel();
		}
	}, []);

	return (
        <React.Fragment>
            {tankerTruck &&
                <Styled>
                    <NavLink to={`/tankertrucks`}>
                        <div className="pcar">{moment(tankerTruck.date).format('DD-MM-YYYY')}</div>
                        <div className="icon">
                            <i className="bi bi-x"></i>
                        </div>
                        <div className="message">LAS IMÁGENES NO HAN SIDO<br />PROCESADAS</div>
                    </NavLink>
                </Styled>
            }
        </React.Fragment>
	);
}


