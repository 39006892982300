import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUser } from 'helpers/user';
import Conteos from 'services/Conteos';

const StyledElement = styled.div`
	--heightWidth: 35px;

	position: absolute;
	top: calc(50% - (var(--heightWidth) / 2));
	right: calc((var(--heightWidth) / 2) * -1);
	background: var(--plenoil-red);
	border: 4px solid #09857b;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	height: var(--heightWidth);
	width: var(--heightWidth);
	font-size: calc(var(--heightWidth) * 0.4);
	line-height: calc(var(--heightWidth) * 0.4);
	border-radius: 50%;
`;

export default function ConteosPendingIntervalChecker() {
	let [count, setCount] = useState(0);

	useEffect(() => {
		const getCount = async () => {
			let data = await Conteos.getActiveCampaigns(getUser()?.stations[0] ?? null);
			
			let count = data.length;
			setCount(count);
		};

		let getCountInterval = setInterval(() => {
			getCount();
		}, 5000);
		getCount();

		return function cleanup() {
			clearInterval(getCountInterval);
			Conteos.cancel();
		}
	}, []);

	return (
		<>
			{count > 0 && <StyledElement>{count}</StyledElement>}
		</>
	);
}


