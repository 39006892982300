import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { default as PricesService } from 'services/Prices';

const StyledElement = styled.div`
	--heightWidth: 20px;

	display: ${props => props.display === "true" ? 'flex' : 'none'};
	position: absolute;
	top: calc(50% - (var(--heightWidth) / 2));
	right: calc((var(--heightWidth) / 2) * -1);
	height: var(--heightWidth);
	width: var(--heightWidth);
	background: var(--plenoil-red);
	color: white;
	border-radius: 50%;
	font-size: calc(var(--heightWidth) * 0.6);
	align-items: center;
	justify-content: center;
`;

export default function PricesControlCurrentIntervalChecker() {
	let [current, setCurrent] = useState(null);

	useEffect(() => {
		const getData = async () => {
			let intervals = await PricesService.getCompetitionIntervalList();
			if ( !intervals ) return;
			
			let current = intervals.filter(el => el.current === true && el.status !== 1)[0] ?? null; 
			setCurrent(current);
		};

		let getCurrentInterval = setInterval(() => {
			getData();
		}, 10000);
		getData();

		return function cleanup() {
			clearInterval(getCurrentInterval);
			PricesService.cancel();
		}
	}, []);

	return (
        <StyledElement className="parpadeo" display={current ? "true" : "false"}>
			<i className="bi bi-exclamation-triangle"></i>
        </StyledElement>
	);
}


