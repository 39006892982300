import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const ClockStyled = styled.div` 
	font-size: 70px;
	font-weight: lighter;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: auto;
	letter-spacing: -5px;
	color: white;
`;

export default function Clock() {
	let [time, setTime] = useState(new Date());

	useEffect(() => {
		let interval = setInterval(() => {
			setTime(new Date());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
        <ClockStyled id="clock">
			<div>{moment(time).format('HH:mm')}</div>
		</ClockStyled>
	);
}


