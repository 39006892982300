import React, { useEffect, useState } from 'react';
import { useNavigate }  from 'react-router-dom';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { assets } from 'helpers/generic';
import { getUser } from 'helpers/user';
import Conteos from 'services/Conteos';
import moment from 'moment';

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-orange);
	border-radius: 50%;
	font-size: 20px;
	opacity: 0.7;
`;

const Wrapper = styled.div`
	--conteo-color: #00738D;

	width: 100%;
	height: 100%;
	background: #fffaf6;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;

		h1 {
			margin: 0;
			padding: 0;
			font-size: 20px;

			img {
				margin-right: 5px;
			}
		}

	}

	.body {

		.conteo {
			margin-top: 15px;
			margin-bottom: 15px;
			cursor: pointer;

			.dealer {
				color: var(--conteo-color);
				text-transform: uppercase;
				margin-left: 20%;
			}

			.white-box {
				background: white;
				display: flex;
				border-top: 1px solid #ebebeb;
				border-bottom: 1px solid #ebebeb;
				padding-top: 5px;
				padding-bottom: 5px;

				.left {
					width: 20%;
					display: flex;
					align-items: center;
					justify-content: end;
					
					img {
						width: 45%;
					}
				}

				.right {
					width: 80%;

					.competency, .day {

						span {
							display: block;
							color: gray;
						}

						strong {
							color: black;
						}
					}

					.day {

					}
				}
			}
		}
	}
	
`;

export default function ConteosList() {
	let navigate = useNavigate();

	let [data, setData] = useState([]);

	useEffect(() => {
		let getData = async () => {
			let data = await Conteos.getActiveCampaigns(getUser()?.stations[0] ?? null);
			setData(data);	
		}
		getData();

		return function cleanup() {
			Conteos.cancel();
		}
	}, []);

	const goToForm = (index) => {
		let conteo = data[index];

		// Save selected conteo in session storage to prevent reload from API data
		window.sessionStorage.setItem('selectedConteo', JSON.stringify(conteo));

		navigate('/conteos/form/' + conteo.id)
	}
	
	return (
		<LoggedLayout hideFooter={true}>
			<Wrapper>
				<div className="top">
					<CloseButton onClick={() => navigate('/')}>
						<i className="bi bi-chevron-left"></i>
					</CloseButton>
					<h1>
						<img src={assets('/img/conteo-icono.svg')} alt="Conteo" width="30" />
						Conteos
					</h1>
				</div>

				<div className="body">
					{data?.map((conteo, index) => {
						return (
							<div className="conteo" key={index} onClick={() => goToForm(index)}>
								<div className="dealer">{conteo.dealer ?? ''}</div>
								<div className="white-box">
									<div className="left">
										<img src={assets('/img/conteo-icono-solo-blue.svg')} />
									</div>
									<div className="right">
										<div className="competency">
											<span>Competencia</span>
											<strong>{conteo?.competitor?.name}</strong>
										</div>
										<div className="day">
											<span>Día</span>
											<strong>{conteo?.date_execution ? moment(conteo.date_execution).format('DD/MM/YYYY') : ''}</strong>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</Wrapper>
		</LoggedLayout>
	);
}


